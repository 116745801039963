import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { colors } from '../../helpers/colors/colors';
import { respondTo } from '../../helpers/breakpoints/breakpoints';

import {
  scaleLineHeight,
  FontWeight,
  HeadlineSizeKeys,
  HEADLINE_SIZES as SIZES,
} from '../../helpers/typography/typography';

export interface HeadlineProps extends React.HTMLAttributes<HTMLElement> {
  as: string;
  fontWeight?: FontWeight;
  innerRef?: HTMLElement | null;
  uppercase?: boolean;
  isPrimary?: boolean;
  isNarrow?: boolean;
  align?: 'left' | 'center' | 'right';
  hasSpacing?: boolean;
  none?: HeadlineSizeKeys;
  sm?: HeadlineSizeKeys;
  md?: HeadlineSizeKeys;
  lg?: HeadlineSizeKeys;
  xl?: HeadlineSizeKeys;
  ['2xl']?: HeadlineSizeKeys;
}

export const Headline = styled.div.attrs({
  className: '',
})<HeadlineProps>`
  text-align: ${(props) => props.align};
  font-weight: ${(props) => props.fontWeight};
  margin-top: 0;
  margin-bottom: ${(props) =>
    props.hasSpacing ? `${space(1)}` : `${space(0.25)}`};
  ${(props) => props.uppercase && 'text-transform: uppercase'};
  ${(props) => props.isPrimary && `color: ${colors.green}`};
  line-height: ${(props) => (props.isNarrow ? '1' : space(2))};
  font-size: ${SIZES['default']}px;
  width: 100%;

  ${(props) => {
    if (props['none']) {
      return `font-size: ${SIZES[props['none']]}px;`;
    }
  }}
  ${(props) => {
    if (props['none']) {
      return !props.hasSpacing
        ? `margin-bottom: ${space(0.25)};`
        : `margin-bottom: ${scaleLineHeight(SIZES[props['none']])};`;
    }
  }}

  ${(props) => {
    if (props['sm']) {
      return respondTo('sm', {
        ['font-size']: `${SIZES[props['sm']]}px`,
        ['margin-bottom']: !props.hasSpacing
          ? `${space(0.25)}`
          : `${scaleLineHeight(SIZES[props['sm']])}`,
      });
    }
  }}

  ${(props) => {
    if (props['md']) {
      return respondTo('md', {
        ['font-size']: `${SIZES[props['md']]}px`,
        ['margin-bottom']: !props.hasSpacing
          ? `${space(0.25)}`
          : `${scaleLineHeight(SIZES[props['md']])}`,
      });
    }
  }}

  ${(props) => {
    if (props['lg']) {
      return respondTo('lg', {
        ['font-size']: `${SIZES[props['lg']]}px`,
        ['margin-bottom']: !props.hasSpacing
          ? `${space(0.25)}`
          : `${scaleLineHeight(SIZES[props['lg']])}`,
      });
    }
  }}

  ${(props) => {
    if (props['xl']) {
      return respondTo('xl', {
        ['font-size']: `${SIZES[props['xl']]}px`,
        ['margin-bottom']: !props.hasSpacing
          ? `${space(0.25)}`
          : `${scaleLineHeight(SIZES[props['xl']])}`,
      });
    }
  }}
`;

export const StyledHeadline: React.FC<HeadlineProps> = (
  props: HeadlineProps
): JSX.Element => {
  const {
    align = 'left',
    children,
    as,
    fontWeight = 600,
    innerRef,
    uppercase = false,
    isPrimary = false,
    isNarrow = false,
    hasSpacing = false,
  } = props;

  return (
    <Headline
      align={align}
      as={as}
      fontWeight={fontWeight}
      ref={innerRef}
      uppercase={uppercase}
      isPrimary={isPrimary}
      isNarrow={isNarrow}
      hasSpacing={hasSpacing}
      {...props}
    >
      {children}
    </Headline>
  );
};
