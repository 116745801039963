/* eslint snapengage/no-colors: 0 */

export const colors = {
  transparent: "transparent",
  black: "#111417",
  grey110: "#1C2429",
  grey100: "#1e2833",
  grey90: "#364453",
  grey80: "#65727f",
  grey70: "#858F99",
  grey40: "#AEB5BD",
  grey30: "#C6CCD2",
  grey20: "#ECEEEF",
  grey10: "#f5f6f7",
  white: "#ffffff",
  skyBlue: "#0091FA",
  skyBlue90: "#1B3247",
  skyBlue80: "#183D5B",
  skyblue60: "#0475E7",
  skyBlue40: "#80C8FC",
  skyBlue20: "#CCE9FE",
  skyBlue10: "#e6f4fe",
  green90: "#214000",
  green80: "#2D4929",
  green60: "#60B904",
  green40: "#C3EB99",
  green20: "#e1f5cc",
  green10: "#e8f7da",
  orange90: "#402600",
  orange80: "#603B04",
  orange60: "#FF8800",
  orange40: "#FFD699",
  orange20: "#ffebcc",
  orange10: "#fff5e6",
  red90: "#400800",
  red80: "#560E03",
  red60: "#B81E1E",
  red40: "#F0A6A6",
  red20: "#f7d3d3",
  red10: "#fbe9e9",
  yellow90: "#443B11",
  yellow80: "#695a17",
  yellow60: "#FCC203",
  yellow40: "#FFEC99",
  yellow20: "#fff6cc",
  yellow10: "#fffae6",
  yellow: "#ffd000",
  purple: "#9F2B68",
  pink: "#e9b1b2",
  blue: "#1362F6",
  orange: "#ff9900",
  green: "#34C375",
  red: "#d92121",
  light: "#ffffff",
  dark: "#111417",
  brand: "#34c375",
} as const

export type ColorKeys = keyof typeof colors
export type ColorValues = typeof colors[ColorKeys]

export const hexToRgb = (hex: string, format = true): number[] | string => {
  if (hex.startsWith("#")) {
    hex = hex.replace("#", "")
  }
  if (hex.length === 3) {
    // from FFF to FFFFFF
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  if (format) {
    return `rgb(${r},${g},${b})`
  } else {
    return [r, g, b]
  }
}

export const hexToRgba = (hex: string, a: number): string => {
  const [r, g, b] = hexToRgb(hex, false) as number[]
  return `rgba(${r},${g},${b}, ${a})`
}
