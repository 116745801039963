import { space } from '../space';
export const PARAGRAPH_SIZES = {
  tiny: 6,
  ['extra-small']: 8,
  small: 12,
  default: 17,
  medium: 20,
  large: 24,
  ['extra-large']: 32,
};

export type ParagraphSizeKeys = keyof typeof PARAGRAPH_SIZES;
export type ParagraphSizeValues = typeof PARAGRAPH_SIZES[ParagraphSizeKeys];

export const HEADLINE_SIZES = {
  default: 20,
  tiny: 27,
  ['extra-small']: 34,
  small: 42,
  medium: 80,
  large: 120,
  ['extra-large']: 180,
};

export function scaleLineHeight(fontSize: number): string {
  let spacing = '0rem';
  switch (fontSize) {
    case HEADLINE_SIZES['default']:
      spacing = space(0.5);
      break;
    case HEADLINE_SIZES['tiny']:
      spacing = space(0.5);
      break;
    case HEADLINE_SIZES['extra-small']:
      spacing = space(0.75);
      break;
    case HEADLINE_SIZES['small']:
      spacing = space(1.5);
      break;
    case HEADLINE_SIZES['medium']:
      spacing = space(1.5);
      break;
    case HEADLINE_SIZES['large']:
      spacing = space(2);
      break;
    case HEADLINE_SIZES['extra-large']:
      spacing = space(2);
      break;
  }
  return spacing;
}

export type HeadlineSizeKeys = keyof typeof HEADLINE_SIZES;
export type HeadlineSizeValues = typeof HEADLINE_SIZES[HeadlineSizeKeys];

export type FontWeight = 'normal' | 'bold' | 300 | 400 | 500 | 600 | 800;
