import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { colors, hexToRgba } from '../../helpers/colors/colors';
import { Link as BaseLink } from '../Link/Link';

import { respondTo } from '../../helpers/breakpoints/breakpoints';

export interface FooterProps extends React.HTMLAttributes<HTMLElement> {
  changeModal: (modalName: string) => void;
  toggleTheme: (e: React.MouseEvent) => void;
}

const Link = styled(BaseLink)`
  color: ${hexToRgba(colors.black, 0.8)};
  @media (prefers-color-scheme: dark) {
    color: ${hexToRgba(colors.white, 0.8)};
  }

  @media (prefers-color-scheme: light) {
    color: ${hexToRgba(colors.black, 0.8)};
  }
`;

export const Footer = styled.footer.attrs({
  className: '',
})<FooterProps>`
  position: static;
  bottom: 0;
  left: 0;
  padding: ${space(0.5)};
  margin-top: ${space(2)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: no-wrap;
  z-index: 2000;
  ${respondTo('sm', {
    ['flex-direction']: `row`,
  })}
  ${respondTo('lg', {
    ['position']: `fixed`,
    ['width']: '100%',
  })}
  background: ${colors.white};
  ${respondTo('xl', {
    ['background']: `transparent !important`,
  })}

  > div {
    flex: 1 1 auto;
    padding: ${space(0.5)};
    display: flex;
    align-items: center;
    justify-content: center;
    ${respondTo('xl', {
      ['padding']: `${space(3)};`,
    })}
    &:first-child {
      margin-left: -${space(0.5)};
      justify-content: center;
      ${respondTo('sm', {
        ['justify-content']: `flex-start`,
      })}
      ${respondTo('lg', {
        ['margin-left']: 0,
      })}
      ${respondTo('xl', {
        ['margin-left']: 0,
        ['justify-content']: `flex-start`,
      })}
    }
    &:last-child {
      margin-right: -${space(0.5)};
      justify-content: center;
      ${respondTo('sm', {
        ['justify-content']: `flex-end`,
      })}
      ${respondTo('lg', {
        ['margin-right']: 0,
      })}
      ${respondTo('xl', {
        ['justify-content']: `flex-end`,
      })}
    }
    a,
    span {
      padding: ${space(0.5)};
    }
    a {
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
    color: ${hexToRgba(colors.black, 0.8)};
    @media (prefers-color-scheme: dark) {
      color: ${hexToRgba(colors.white, 0.8)};
    }

    @media (prefers-color-scheme: light) {
      color: ${hexToRgba(colors.black, 0.8)};
    }
  }
  @media (prefers-color-scheme: dark) {
    background: ${colors.black};
  }

  @media (prefers-color-scheme: light) {
    background: ${colors.white};
  }
`;

export const StyledFooter: React.FC<FooterProps> = (
  props: FooterProps
): JSX.Element => {
  const { children } = props;

  return (
    <Footer>
      {children}
      <div>
        <span>
          &copy; {`${new Date().getFullYear().toString()} Klickste UG`}
        </span>
      </div>
      <div>
        <Link to={'/impressum'}>Impressum</Link>
        <Link to={'/datenschutz'}>Datenschutz</Link>
      </div>
    </Footer>
  );
};

export default StyledFooter;
