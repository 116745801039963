import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { colors } from '../../helpers/colors/colors';
import { respondTo, breakpoints } from '../../helpers/breakpoints/breakpoints';
import {
  ParagraphSizeKeys,
  PARAGRAPH_SIZES as SIZES,
} from '../../helpers/typography/typography';

export interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
  as?: 'button' | 'a';
  to?: string;
  onClick?: (e: React.MouseEvent) => void;
  hasBorder?: boolean;
  isPrimary?: boolean;
  hasEmphasis?: boolean;
  none?: ParagraphSizeKeys;
  sm?: ParagraphSizeKeys;
  md?: ParagraphSizeKeys;
  lg?: ParagraphSizeKeys;
  xl?: ParagraphSizeKeys;
  ['2xl']?: ParagraphSizeKeys;
}
export const Button = styled.div.attrs({
  className: '',
})<ButtonProps>`
  display: flex;
  flex-direction: row;
  padding: ${space(0.25)};
  min-width: 32px;
  min-height: 32px;
  border-radius: 2px;
  border-width: 3px;
  border-style: solid;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.hasEmphasis ? 600 : 400)};
  cursor: pointer;
  &:focus-visible {
    border: 0;
    outline: 0;
  }
  * > {
    &:only-child {
      margin-right: 0;
    }
  }

  border-color: ${colors.black};
  background: transparent;
  color: ${colors.black};
  @media (prefers-color-scheme: dark) {
    border-color: ${colors.white};
    background: ${colors.black};
    color: ${colors.white};
  }
  @media (prefers-color-scheme: light) {
    border-color: ${colors.black};
    background: ${colors.white};
    color: ${colors.black};
  }
  ${(props) =>
    (!props.hasBorder || props.isPrimary) &&
    `border-color: transparent !important`};
  ${(props) => props.isPrimary && `background: ${colors.green} !important`};
  ${(props) => props.isPrimary && `color: ${colors.white} !important`};
  font-size: ${SIZES['default']}px;

  ${(props) => {
    return Object.keys(breakpoints).forEach((bp) => {
      if (props[bp]) {
        return respondTo(bp, {
          ['font-size']: `${SIZES[props[bp]]}px`,
        });
      }
    });
  }}

  ${(props) => {
    if (props['none']) {
      return `font-size: ${SIZES[props['none']]}px;`;
    }
  }}

  ${(props) => {
    if (props['sm']) {
      return respondTo('sm', {
        ['font-size']: `${SIZES[props['sm']]}px`,
      });
    }
  }}

  ${(props) => {
    if (props['md']) {
      return respondTo('md', {
        ['font-size']: `${SIZES[props['md']]}px`,
      });
    }
  }}

  ${(props) => {
    if (props['lg']) {
      return respondTo('lg', {
        ['font-size']: `${SIZES[props['lg']]}px`,
      });
    }
  }}

  ${(props) => {
    if (props['xl']) {
      return respondTo('xl', {
        ['font-size']: `${SIZES[props['xl']]}px`,
      });
    }
  }}
`;

export const StyledButton: React.FC<ButtonProps> = (
  props: ButtonProps
): JSX.Element => {
  const {
    as = 'button',
    children,
    onClick,
    hasBorder = false,
    hasEmphasis = true,
    isPrimary = false,
    to,
  } = props;

  return (
    <Button
      as={as}
      href={to && to}
      onClick={onClick && onClick}
      hasBorder={hasBorder}
      hasEmphasis={hasEmphasis}
      isPrimary={isPrimary}
      {...props}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
