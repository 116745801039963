import React from 'react';
import styled from 'styled-components';
import { Link as BaseLink } from 'gatsby';
import { StyledButton as Button } from '../button/Button';

export interface LinkProps extends React.HTMLAttributes<HTMLElement> {}

export const Link = styled(BaseLink)<LinkProps>`
  &:hover,
  &:visited,
  &:active {
    color: inherit;
  }
  text-decoration: none;
`;

export const StyledLink: React.FC<LinkProps> = (
  props: LinkProps
): JSX.Element => {
  return (
    <Link {...props}>
      <Button></Button>
    </Link>
  );
};

export default StyledLink;
