import React from 'react';
import styled from 'styled-components';

// Components
import {
  library,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fal, fas, fab);

// Helpers
import { space } from '../../helpers/space';
import { colors } from '../../helpers/colors/colors';

export interface SymbolProps extends React.HTMLAttributes<HTMLElement> {
  name?: string;
  size?:
    | 'xs'
    | 'lg'
    | 'sm'
    | '1x'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x';
  spinning?: boolean;
  className?: string;
  color?: string;
  symbolStyle?: IconPrefix;
  symbolName?: IconName;
}

export const Symbol = styled.span.attrs({
  className: '',
})<SymbolProps>`
  padding: ${space(0.25)};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
`;

Symbol.defaultProps = {
  symbolStyle: 'fal',
  size: '1x',
  spinning: false,
  symbolName: 'music',
};

export const StyledSymbol: React.FC<SymbolProps> = (
  props: SymbolProps
): JSX.Element => {
  const { symbolStyle, symbolName, spinning, size } = props;

  return (
    <Symbol>
      <FontAwesomeIcon
        color={'inherit'}
        spin={spinning}
        icon={[symbolStyle, symbolName]}
        size={size}
      />
    </Symbol>
  );
};

export default StyledSymbol;
