import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { respondTo } from '../../helpers/breakpoints/breakpoints';
import {
  FontWeight,
  ParagraphSizeKeys,
  PARAGRAPH_SIZES as SIZES,
} from '../../helpers/typography/typography';

export interface ParagraphProps extends React.HTMLAttributes<HTMLElement> {
  align?: 'right' | 'left' | 'center';
  fontWeight?: FontWeight;
  insetLeft?: string;
  insetRight?: string;
  isNarrow?: boolean;
  hasSpacing?: boolean;
  none?: ParagraphSizeKeys;
  sm?: ParagraphSizeKeys;
  md?: ParagraphSizeKeys;
  lg?: ParagraphSizeKeys;
  xl?: ParagraphSizeKeys;
  ['2xl']?: ParagraphSizeKeys;
}

export const Paragraph = styled.p.attrs({
  className: '',
})<ParagraphProps>`
  line-height: ${(props) => (props.isNarrow ? space(1) : space(1.5))};
  ${(props) => {
    return respondTo('sm', {
      ['line-height']: `${props.isNarrow ? space(1.5) : space(2)}`,
    });
  }}

  font-weight: ${(props) => props.fontWeight};
  margin-left: ${(props) => props.insetLeft};
  margin-right: ${(props) => props.insetRight};
  margin-top: 0;
  margin-bottom: ${(props) => (props.hasSpacing ? `${space(1)}` : 0)};
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.align === 'left' ? 'flex-start' : 'flex-end'};
  text-align: ${(props) => props.align};

  ${(props) => {
    if (props['none']) {
      return `font-size: ${SIZES[props['none']]}px;`;
    }
  }}

  ${(props) => {
    if (props['sm']) {
      return respondTo('sm', {
        ['font-size']: `${SIZES[props['sm']]}px`,
      });
    }
  }}

  ${(props) => {
    if (props['md']) {
      return respondTo('md', {
        ['font-size']: `${SIZES[props['md']]}px`,
      });
    }
  }}

  ${(props) => {
    if (props['lg']) {
      return respondTo('lg', {
        ['font-size']: `${SIZES[props['lg']]}px`,
      });
    }
  }}

  ${(props) => {
    if (props['xl']) {
      return respondTo('xl', {
        ['font-size']: `${SIZES[props['xl']]}px`,
      });
    }
  }}
`;

export const StyledParagraph: React.FC<ParagraphProps> = (
  props: ParagraphProps
): JSX.Element => {
  const { children, isNarrow = false, hasSpacing = true } = props;

  return (
    <Paragraph isNarrow={isNarrow} hasSpacing={hasSpacing} {...props}>
      {children}
    </Paragraph>
  );
};

export default StyledParagraph;
