import * as React from 'react';
import styled from 'styled-components';

import { colors } from '../../helpers/colors/colors';
import { Link } from 'gatsby';

export interface LogoProps extends React.HTMLAttributes<HTMLElement> {
  rotation?: number;
  height?: number;
  hasWordmark?: boolean;
  to?: string;
  onClick?: () => void;
  innerRef?: HTMLElement | null;
}

export const Logo = styled.div<LogoProps>`
  display: flex;
  position: relative;
  flex: 0 1 auto;
  &:hover {
    cursor: ${(props) => (props.onClick || props.to ? 'pointer' : 'inherit')};
  }
  width:  ${(props) =>
    props.hasWordmark
      ? `${(442 * props.height) / 100}px;`
      : `${props.height}px;`}
 

  overflow: hidden;

  svg {
    &.wordmark {
      position: absolute;
      transform-origin: ${(props) => props.height / 2}px
        ${(props) => props.height / 2}px;
      transform: rotate(${(props) => props.rotation}deg);
      transition: transform 0.5s ease-in-out;
    }
  }
  .shape,
  .symbol,
  .type {
  }
  .shape {
    fill: ${colors.brand};
  }
  .symbol {
    fill: ${colors.white};
  }
  .type {
    fill: ${colors.white};
    position: absolute;
    left: 0px;
  }
  @media (prefers-color-scheme: dark) {
    .symbol {
      fill: ${colors.black};
    } 
    .type {
      fill: ${colors.white};
    }
  }
  @media (prefers-color-scheme: light) {
    .symbol {
      fill: ${colors.white};
    }
    .type {
      fill: ${colors.black};
    }
  }
`;

export const StyledLogo: React.FC<LogoProps> = (
  props: LogoProps
): JSX.Element => {
  const {
    rotation = 0,
    height = 64,
    hasWordmark = false,
    to,
    onClick,
    innerRef,
  } = props;

  return (
    <Logo
      rotation={rotation}
      height={height}
      hasWordmark={hasWordmark}
      to={to}
      onClick={onClick}
      ref={innerRef}
      {...props}
    >
      {to ? <Link to={to} /> : <a onClick={onClick}></a>}
      <svg
        width={height}
        height={height}
        viewBox={`0 0 100 100`}
        xmlns="http://www.w3.org/2000/svg"
        className="arrow"
      >
        <path
          className="shape"
          d="M82.1874 11.25C73.7499 5.85534 61.2499 5 49.9999 5C38.7499 5 26.25 5.85534 17.8125 11.25C15.1138 12.9754 12.9754 15.1138 11.25 17.8125C5.85534 26.25 5 38.7499 5 49.9999C5 61.2499 5.85534 73.7499 11.25 82.1874C12.9754 84.8861 15.1138 87.0245 17.8125 88.7498C26.25 94.1445 38.7499 94.9998 49.9999 94.9998C61.2499 94.9998 73.7499 94.1445 82.1874 88.7498C84.8861 87.0245 87.0245 84.8861 88.7498 82.1874C94.1445 73.7499 94.9998 61.2499 94.9998 49.9999C94.9998 38.7499 94.1445 26.25 88.7498 17.8125C87.0245 15.1138 84.8861 12.9754 82.1874 11.25Z"
        ></path>
        <path
          className="symbol"
          d="M74.6517 25.3483C74.226 24.9226 73.4324 25.1668 71.8452 25.6552L26.1169 39.7255C23.7172 40.4638 22.5174 40.833 22.5035 41.5568C22.4897 42.2806 23.6746 42.6953 26.0444 43.5248L42.3004 49.2144L29.494 62.0209C28.1606 63.3543 27.494 64.0209 27.494 64.8493C27.494 65.6778 28.1606 66.3444 29.494 67.6778L32.3224 70.5062C33.6557 71.8396 34.3224 72.5062 35.1508 72.5062C35.9792 72.5062 36.6459 71.8396 37.9792 70.5062L50.7857 57.6997L56.4753 73.9559C57.3048 76.3256 57.7195 77.5105 58.4433 77.4967C59.167 77.4829 59.5362 76.283 60.2746 73.8833L74.3449 28.1549C74.8333 26.5677 75.0774 25.7741 74.6517 25.3483Z"
        ></path>
      </svg>
      {hasWordmark && (
        <svg
          width={`${442 * (height / 100)}`}
          height={height}
          viewBox={`0 0 442 100`}
          xmlns="http://www.w3.org/2000/svg"
          className="wordmark"
        >
          <path
            className="type"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M244 83C258.237 83 265.348 73.4984 266.742 56H253.687C252.757 65.0148 249.708 71 244 71C237 71 234 62 234 50.5499C234 37 237 29 244 29C249.916 29 252.975 34.7143 253.781 44H266.802C265.562 28.4235 258.453 17 244 17C228 17 221 31 221 50.8641C221 71 228 83 244 83ZM114 18H127V50V82H114V18ZM145 82L127 50L145 18H158L140 50L158 82H145ZM164 18H177V70H196V82H177H164V18ZM202 18H215V82H202V18ZM286 18H273V82H286V50L304 82H317L299 50L317 18H304L286 50V18ZM338.5 17C326 17 319 25 319 35C319 51 331 54 337 55C343 56 346 60 346 64C346 68 343 71 338.5 71C334 71 331 68 331 63H318C318 75 327 83 338.5 83C350 83 359 74 359 64C359 50 346 45 341 44C336 43 332 41 332 35C332 32 334 29 338.5 29C343 29 345 32 345 36H358C358 25 351 17 338.5 17ZM361 18H373H386H398V30H386V82H373V30H361V18ZM403 18H416H438V30H416V44H435V56H416V70H438V82H416H403V18Z"
          ></path>
        </svg>
      )}
    </Logo>
  );
};

export default StyledLogo;
