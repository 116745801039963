type SpaceUnits =
  | 0.125
  | 0.25
  | 0.375
  | 0.5
  | 0.75
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 4
  | 6
  | 8
  | 12;
const allowedValues = [
  1 / 8,
  1 / 4,
  3 / 8,
  1 / 2,
  3 / 4,
  1,
  3 / 2,
  2,
  5 / 2,
  3,
  4,
  6,
  8,
  12,
];

/**
 * provides a consistent way of spacing elements.
 * @param {SpaceUnits} unit - the base unit measurement is 0.5rem (8px).
 * Conversion table:
 * 0.125 rem = 2px
 * 0.25 rem = 4px
 * 0.375 rem = 6px
 * 0.5 rem = 8px
 * 0.75 rem = 12px
 * 1 rem = 16px
 */
export function space(unit: SpaceUnits): string {
  if (!isAValidSpaceUnit(unit)) {
    // eslint-disable-next-line
    throw new Error(`Use a valid spacing unit. Received: ${unit}.`);
  }

  return `${unit}rem`;
}

export function isAValidSpaceUnit(unit: number): unit is SpaceUnits {
  return allowedValues.includes(unit);
}
