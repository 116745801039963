import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { colors } from '../../helpers/colors/colors';

import { respondTo } from '../../helpers/breakpoints/breakpoints';

export interface HeaderProps extends React.HTMLAttributes<HTMLElement> {}

export const Header = styled.header.attrs({
  className: '',
})<HeaderProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${space(4)};
  z-index: 2000;
  @media (prefers-color-scheme: dark) {
    background: ${colors.black};
  }
  @media (prefers-color-scheme: light) {
    background: ${colors.white};
  }
  ${respondTo('xl', {
    ['background']: `transparent`,
  })}
  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    > div {
      padding: ${space(0.5)};
      display: flex;
      align-items: center;
      justify-content: center;

      ${respondTo('xl', {
        ['padding']: `${space(3)};`,
      })}

      &:first-child {
        flex: 0 1 60px;
        justify-content: flex-start;
      }
      &:last-child {
        flex: 1 1 50%;
        justify-content: flex-end;
      }
      &:only-child {
        justify-content: flex-start;
      }
    }
  }
`;

export const StyledHeader: React.FC<HeaderProps> = (
  props: HeaderProps
): JSX.Element => {
  const { children } = props;

  return (
    <Header>
      <div className="container">{children}</div>
    </Header>
  );
};

export default StyledHeader;
